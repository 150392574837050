.poll {
  &-vote {
    height: 17px;
  }
}

.toRight {
  position: absolute;
  left: 100%;
}

.public-ad {
  max-height: 340px;
  height: 340px;
  overflow-y: auto;
  p {
    margin-bottom: 0;
  }
}

.slider {
  &-wrapper {
    max-height: 420px;
    height: 420px;
  }
  .slick-list,
  .slick-track {
    height: 100%;
  }
}

@media (max-width: 768px) {
  .photosBlock {
    padding-left: 0 !important;
    border-radius: 8px;
  }
  .adsBlock, .pollBlock {
    background-color: #182B53;
    color: #fff;
  }
}
